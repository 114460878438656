/**
* 路由可访问性
*/

/* eslint-disable */

// 账户类型id => 路由名称数组
export const accountTypes = {
    1: ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl',            'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart',                                 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl', 'Tools', 'StatScreen', 'StatOverview',                                                 'AppLog', 'FirmwareManage', 'GroundStationConfig', 'PartWhiteList'],
    2: ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl',                                        'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate'],
    3: ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart',                'AccountDetail',                                                                       'FlightControl',                                        'DroneModelList',             'SubCompanyList',           'FirmwareManage', 'GroundStationConfig'],
    4: ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl',            'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart',                'AccountDetail',                                                                                                                                                 'TeamList', 'SubCompanyList'],
};

// 账号类型id => 路由名称数组
export const userTypes = {
    1: ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl', 'Tools', 'StatScreen', 'StatOverview', 'DroneModelList', 'TeamList', 'SubCompanyList', 'AppLog', 'FirmwareManage', 'GroundStationConfig', 'PartWhiteList'],
    3: ['Workbench', 'MonitorView',                  'DataStatList', 'DroneStatistics', 'BulletinList',                                                                                                                                                                                                                                                                                                                'StatScreen', 'StatOverview',                                                           'FirmwareManage'],
    4: ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl', 'Tools', 'StatScreen', 'StatOverview', 'DroneModelList', 'TeamList', 'SubCompanyList', 'AppLog', 'GroundStationConfig'],
    5: ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList',                                'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl', 'Tools', 'StatScreen', 'StatOverview', 'DroneModelList', 'TeamList', 'AppLog'],
    2: [],
};

// 2023/9/13 子平台 tag => 路由名称数组 StatScreen
export const subPlatformType = {
    1: ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList',                                      'FlightControl', 'Tools',                'StatOverview', 'DroneModelList', 'TeamList', 'SubCompanyList'],
}

// 根据域名 区分访问界面
export const domainNameRoute = {
    'ag.jiagutech.com': [   'Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl', 'Tools', 'StatScreen', 'StatOverview', 'DroneModelList', 'TeamList', 'SubCompanyList', 'AppLog',                    'GroundStationConfig', 'PartWhiteList'],
    'test2.jiagutech.com': ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl', 'Tools', 'StatScreen', 'StatOverview', 'DroneModelList', 'TeamList', 'SubCompanyList', 'AppLog', 'FirmwareManage'],
    'ag-th.jiagutech.com': ['Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl', 'Tools', 'StatScreen', 'StatOverview', 'DroneModelList', 'TeamList', 'SubCompanyList', 'AppLog'],
    'localhost:8080': [     'Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl', 'Tools', 'StatScreen', 'StatOverview', 'DroneModelList', 'TeamList', 'SubCompanyList', 'AppLog', 'FirmwareManage'],
    'ag.xagrotortek.com': [              'MonitorView',                                  'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList',                 'RtkList', 'OrderList', 'InvoiceList',                    'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl',                                        'DroneModelList', 'TeamList', 'SubCompanyList',           'FirmwareManage'],
    'dgca.aipl.cloud': [                 'MonitorView',                                  'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList',                 'RtkList', 'OrderList', 'InvoiceList',                    'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl',                                        'DroneModelList', 'TeamList', 'SubCompanyList',           'FirmwareManage'],
    '192.250.226.217': [                 'MonitorView',                                  'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList',                 'RtkList', 'OrderList', 'InvoiceList',                    'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl',                                        'DroneModelList', 'TeamList', 'SubCompanyList',           'FirmwareManage'],
    'mgcs.marutdrones.com': [            'MonitorView',                                  'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList',                 'RtkList', 'OrderList', 'InvoiceList',                    'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl',                                        'DroneModelList', 'TeamList', 'SubCompanyList',           'FirmwareManage'],
    'syferocompanionpro.com': [          'MonitorView',                                  'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList',                 'RtkList', 'OrderList', 'InvoiceList',                    'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl',                                        'DroneModelList', 'TeamList', 'SubCompanyList',           'FirmwareManage'],
    'pixelrobotics.in': [                'MonitorView',                                  'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList',                 'RtkList', 'OrderList', 'InvoiceList',                    'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl',                                        'DroneModelList', 'TeamList', 'SubCompanyList',           'FirmwareManage'],
    'default': [            'Workbench', 'MonitorView', 'NoFlyZoneList', 'DataStatList', 'DroneStatistics', 'BulletinList', 'SystemLogList', 'MemberList', 'DroneList', 'BlockList', 'GroupControl', 'RtkList', 'OrderList', 'InvoiceList', 'UnifiedTaskList', 'UnifiedTaskDroneList', 'UnifiedTaskChart', 'SubsidyList', 'AccountDetail', 'BulletinCreate', 'AccountList', 'AccountApplyList', 'UserApplyList', 'FlightControl', 'Tools', 'StatScreen', 'StatOverview', 'DroneModelList', 'TeamList', 'SubCompanyList', 'AppLog'],
}
